@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

img {
    image-rendering: -webkit-optimize-contrast;
}

.crox-box-shadow {
    box-shadow: #BE185D99 0 5px 15px;
}

.underline-dotted {
    text-decoration: underline dotted #11182799;
    text-decoration-thickness: 1px;
}

button {
    @apply focus:outline-none;
    @apply focus:ring-1;
    @apply focus:ring-inset;
    @apply focus:ring-primaryDark;
}

a {
    @apply focus:outline-none;
    @apply focus:ring-1;
    @apply focus:ring-inset;
    @apply focus:ring-primaryDark;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

.tippy-box {
    @apply bg-accent !important;
    @apply text-primaryDark !important;
    @apply font-bold !important;
    box-shadow: #BE185D99 0 5px 15px;
}

.tippy-box > .tippy-arrow::before {
    border-top-color: #BE185D !important;
}

.dialog-footer-details {
    transition: max-height 300ms linear;
}
